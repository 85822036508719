fuse-loading-bar {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 6px;




    .app-splash-screen {
        background: #0000007a;
        position: fixed;
        top: 5px;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 100;
        transition: left 0.5s;
      }
      
      .app-label {
        margin-top: 32px;
        color: #fff;
        font-size: 2.5em;
        font-family: "Pacifico", cursive;
      }
      
      .app-splash-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
      .app-logo {
        background: url("../../../../public/images/logo/logo-pdc.gif");
        background-repeat: no-repeat;
        max-width: 100%;
        background-position: center;
        background-size: contain;
        width: 100px;
        height: 100px;
      }
      

}



